<template>
  <div
    class="commonPageBanner"
    :style="{ background: url ? 'url(' + url + ')' : 'url(' + backUrl + ')' }"
  ></div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      backUrl: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/pcBanner3.jpg",
    };
  },
};
</script>

<style scoped lang="less">
.commonPageBanner {
  width: 100%;
  padding-top: 14.6%;
  // background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/inquiryListBanner.jpg);
  background-size: 100% 100% !important;
  margin-bottom: 24px;
}
</style>